import React from "react";
import "../css/Industrial.css";

// Import images directly
import Fire1 from "../images/fireImages/Fire1.png";
import Fire2 from "../images/fireImages/Fire2.png";
import Fire3 from "../images/fireImages/Fire3.png";
import Fire4 from "../images/fireImages/Fire4.png";
import Fire5 from "../images/fireImages/Fire5.png";
import Fire6 from "../images/fireImages/Fire6.png";
import Fire7 from "../images/fireImages/Fire7.png";
import Fire8 from "../images/fireImages/Fire8.png";
import Fire9 from "../images/fireImages/Fire9.png";
import Fire10 from "../images/fireImages/Fire10.png";
import Fire11 from "../images/fireImages/Fire11.png";
import Fire12 from "../images/fireImages/Fire12.png";
import Fire13 from "../images/fireImages/Fire13.png";
import Fire14 from "../images/fireImages/Fire14.png";
import Fire15 from "../images/fireImages/Fire15.png";
import Fire16 from "../images/fireImages/Fire16.png";
import Fire17 from "../images/fireImages/Fire17.png";
import Fire18 from "../images/fireImages/Fire18.png";
import Fire19 from "../images/fireImages/Fire19.png";
import Fire20 from "../images/fireImages/FireDojo.png";

// Products organized by category
const products = {
  FireSafety: [
    { name: "4kg Safety First", image: Fire1 },
    { name: "4kg Safety Fire ABC Type", image: Fire2 },
    { name: "Automatic Modular ABC 5kg", image: Fire3 },
    { name: "Fire Hose Reel Swivelling Type with Pipe & Nozzle", image: Fire4 },
    { name: "4kg Safety Fire Co2 Type", image: Fire5 },
    { name: "4kg Safety Fire ABC Type", image: Fire6 },
    { name: "6kg SP Safety Fire", image: Fire7 },
    { name: "ABC 75kg Trolly Mounted Safety Fire", image: Fire8 },
    { name: "Fire Bucket Stand", image: Fire9 },
    { name: "63mm X 15M Torrent UL", image: Fire10 },
    { name: "Fire Extinguisher Ball ABC Type", image: Fire11 },
    { name: "Fire Hose Box Single Door MS", image: Fire12 },
    { name: "63mm X 15M Torrent Walcoat UL", image: Fire13 },
    { name: "2, 4 & 6kg Safety First", image: Fire14 },
    { name: "FRP Box 600X 750X 300mm For Co2 4.5 kg & ABC 6kg", image: Fire15 },
    { name: "Aluminized Aramid Full Coat", image: Fire16 },
    { name: "Aluminized Molten Metal Suit 3 Layer", image: Fire17 },
    { name: "Fire Proximity Nomex Suit", image: Fire18 },
    { name: "Aluminized Fire Apron with Sleeve", image: Fire19 },
    { name: "Fire Safety Learning Board", image: Fire20 },
  ],
};

// Main Fire component
const Fire = () => {
  return (
    <div className="industrial-container">
      <h2 className="heading">Fire Safety Products</h2>
      <div className="products">
        {products.FireSafety.map((product, index) => (
          <div key={index} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <p className="product-name">{product.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fire;
