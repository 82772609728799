import React from 'react';
import Slider from 'react-slick';
import IndusPic1 from '../images/pic1.jpg';
import IndusPic2 from '../images/pic2.jpg';
import IndusPic3 from '../images/pic3.jpg';
import IndusPic4 from '../images/pic4.jpg';
import '../css/HomeCarousel.css';

const HomeCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const images = [
   IndusPic1,
   IndusPic2,
   IndusPic3,
   IndusPic4,
  ];

  return (
    <div className="carousel-container">
      <Slider {...settings} className='slider'>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image} alt={`Industrial ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeCarousel;
