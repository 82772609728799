import React from 'react';
import '../css/HomeProductSegment.css';
import One from '../images/seg1.jpg';
import Logo from '../images/newlogo7.png';
import AboutImg from '../images/aboutImage.jpg';
import HomeFire from "../images/homeFire.webp";
import HomeRoad from "../images/homeRoad.webp";
import HomeLoto from "../images/homeLoto.webp";

export const SegOne = () => {
    return (
        <img src={One} alt="" className='myImg'/>
    )
}

export const Fire = () => {
    return (
        <img src={HomeFire} alt="" className='myImg'/>
    )
}

export const Road = () => {
    return (
        <img src={HomeRoad} alt="" className='myImg'/>
    )
}

export const Loto = () => {
    return (
        <img src={HomeLoto} alt="" className='myImg'/>
    )
}

export const CompanyLogo = () => {
    return (
        <img src={Logo} alt="" className='myLogo'/>
    )
}

export const AboutImage = () => {
    return (
        <img src={AboutImg} alt="" className='aboutImage'/>
    )
}

