import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import About from "./components/AboutUs";
import Industrial from "./components/Industrial";
import Fire from "./components/Fire";
import Road from "./components/Road";
import ScrollToTop from "./components/ScrollToTop";
import Loto from "./components/Loto";
import Special from "./components/Special";
import Media from "./components/Media";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/products/industrial" element={<Industrial />} />
        <Route path="/products/fire" element={<Fire />} />
        <Route path="/products/road" element={<Road />} />
        <Route path="/products/loto" element={<Loto />} />
        <Route path="/products/special" element={<Special />} />
        <Route path="/media" element={<Media />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
