import React from "react";
import "../css/Industrial.css";

import SP1 from "../images/specialImages/SP1.png";
import SP2 from "../images/specialImages/SP2.png";
import SP3 from "../images/specialImages/SP3.png";
import SP4 from "../images/specialImages/SP4.png";
import SP5 from "../images/specialImages/SP5.png";
import SP6 from "../images/specialImages/SP6.png";
import SP7 from "../images/specialImages/SP7.png";
import SP8 from "../images/specialImages/SP8.png";
import SP9 from "../images/specialImages/SP9.png";
import SP10 from "../images/specialImages/SP10.png";
import SP11 from "../images/specialImages/SP11.png";
import SP12 from "../images/specialImages/SP12.png";
import SP13 from "../images/specialImages/Sp13.png";
import SP14 from "../images/specialImages/SP14.png";
import SP15 from "../images/specialImages/SP15.png";
import SP16 from "../images/specialImages/SP16.png";
import SP17 from "../images/specialImages/SP17.png";
import SP18 from "../images/specialImages/SP18.png";
import SP19 from "../images/specialImages/SP19.png";
import SP20 from "../images/specialImages/SP20.png";
import SP21 from "../images/specialImages/image11.jpg";
import SP22 from "../images/specialImages/image12.jpg";
import SP23 from "../images/specialImages/image13.jpg";
import SP24 from "../images/specialImages/image14.jpg";
import SP25 from "../images/specialImages/image15.jpg";
import SP26 from "../images/specialImages/image16.jpg";
import SP27 from "../images/specialImages/image19.jpg";
import SP28 from "../images/specialImages/image54.jpg";
import SP29 from "../images/specialImages/image55.jpg";
import SP30 from "../images/specialImages/image56.jpg";
import SP31 from "../images/specialImages/image61.jpg";
import SP32 from "../images/specialImages/image62.jpg";

const products = [
  { name: "Alochol Breath Analyzer Portable", image: SP1 },
  { name: "Hand Operated Wall Mounting", image: SP2 },
  { name: "Double Mounting", image: SP3 },
  { name: "LED Rechargeable Search Light", image: SP4 },
  { name: "Double Mounting", image: SP5 },
  { name: "24V DC Siren", image: SP6 },
  { name: "Stretcher First Aid Double Fold", image: SP7 },
  { name: "Wind Sock Indicator", image: SP8 },
  { name: "LED Rechargeable Head Light", image: SP9 },
  { name: "Stretcher First Aid", image: SP10 },
  { name: "Strecher First Aid Fold", image: SP11 },
  { name: "Light Batton Rechargeable", image: SP12 },
  { name: "Oxygen Gas Detector", image: SP13 },
  { name: "Multi functional Rescue Stretcher", image: SP14 },
  { name: "Multi functional Rescue Stretcher SR-RS", image: SP15 },
  { name: "Fire Tarpaulin", image: SP16 },
  { name: "Snake Catcher Stick", image: SP17 },
  { name: "Snake Catcher Steel Hook", image: SP18 },
  { name: "Snake Bite Kit", image: SP19 },
  { name: "Tarpaulin HDPE", image: SP20 },
  { image: SP21 },
  { image: SP22 },
  { image: SP23 },
  { image: SP24 },
  { image: SP25 },
  { image: SP26 },
  { image: SP27 },
  { image: SP28 },
  { image: SP29 },
  { image: SP30 },
  { image: SP31 },
  { image: SP32 },
]

const Special = () => {
  return (
    <div className="industrial-container">
      <h2 className="heading">Special Safety Products</h2>
      <div className="products">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <p className="product-name">{product.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Special;
