import React, { useState, useEffect } from "react";
import "../css/Industrial.css";

// Import images directly
import headProtection from "../images/headProtection.webp";
import eyeProtection from "../images/eyeProtection.png";
import earFaceProtection from "../images/earFaceProtection.webp";
import respiratoryProtection from "../images/respiratoryProtection.avif";
import handProtection from "../images/hendProtection.webp";
import bodyProtection from "../images/bodyProtection.png";
import fallProtection from "../images/fallProtection.jpg";
import feetProtection from "../images/feetProtection.png";
import safetyShower from "../images/safetyShower.jpeg";
import electricalSafety from "../images/electricalSafety.jpeg";
import spillKit from "../images/spillKitt.webp";

// CATALOGE IMAGES

//  EYE PROTECTION
import EyeOne from "../images/industrialImages/eyeProtection/eye1.jpg";
import EyeTwo from "../images/industrialImages/eyeProtection/eye2.jpg";
import EyeThree from "../images/industrialImages/eyeProtection/eye3.png";
import EyeFour from "../images/industrialImages/eyeProtection/eye4.jpg";
import EyeFive from "../images/industrialImages/eyeProtection/eye5.jpg";
import EyeSix from "../images/industrialImages/eyeProtection/eye6.jpg";
import EyeSeven from "../images/industrialImages/eyeProtection/eye7.jpg";
import EyeEight from "../images/industrialImages/eyeProtection/eye8.jpg";
import EyeNine from "../images/industrialImages/eyeProtection/eye9.jpg";
import EyeTen from "../images/industrialImages/eyeProtection/eye10.jpg";
import Eye11 from "../images/industrialImages/eyeProtection/eye11.jpg";
import EyeT12 from "../images/industrialImages/eyeProtection/eye12.jpg";
import EyeT13 from "../images/industrialImages/eyeProtection/eye13.jpg";
import EyeT14 from "../images/industrialImages/eyeProtection/eye14.jpg";

// HEAD PROTECTION
import HelmetOne from "../images/industrialImages/headProtection/Helmet1.jpg";
import HelmetTwo from "../images/industrialImages/headProtection/Helmet2.jpg";
import HelmetThree from "../images/industrialImages/headProtection/Helmet3.jpg";
import HelmetFour from "../images/industrialImages/headProtection/Helmet4.png";
import HelmetFive from "../images/industrialImages/headProtection/Helmet5.png";
import HelmetSix from "../images/industrialImages/headProtection/Helmet6.jpg";
import HelmetSeven from "../images/industrialImages/headProtection/Helmet7.jpg";
import HelmetEight from "../images/industrialImages/headProtection/Helmet8.jpg";
import HelmetNine from "../images/industrialImages/headProtection/Helmet9.jpg";
import HelmetTen from "../images/industrialImages/headProtection/Helmet10.jpg";
import Helmet11 from "../images/industrialImages/headProtection/Helmet11.jpg";
import Helmet12 from "../images/industrialImages/headProtection/Helmet12.jpg";
import Helmet13 from "../images/industrialImages/headProtection/Helmet13.jpg";
import Helmet14 from "../images/industrialImages/headProtection/Helmet14.jpg";

// EAR, FACE & RESPIRATORY
import EFROne from "../images/industrialImages/earFaceRespiratoryProtection/ear1.jpg";
import EFRTwo from "../images/industrialImages/earFaceRespiratoryProtection/ear2.jpg";
import EFR3 from "../images/industrialImages/earFaceRespiratoryProtection/ear3.jpg";
import EFR5 from "../images/industrialImages/earFaceRespiratoryProtection/ear5.jpg";
import EFR6 from "../images/industrialImages/earFaceRespiratoryProtection/ear6.jpg";
import EFR7 from "../images/industrialImages/earFaceRespiratoryProtection/ear7.jpg";
import EFR8 from "../images/industrialImages/earFaceRespiratoryProtection/ear8.jpg";
import EFR9 from "../images/industrialImages/earFaceRespiratoryProtection/ear9.jpg";
import mask1 from "../images/industrialImages/earFaceRespiratoryProtection/mask1.jpg";
import mask2 from "../images/industrialImages/earFaceRespiratoryProtection/mask2.jpg";
import mask3 from "../images/industrialImages/earFaceRespiratoryProtection/mask3.jpg";
import mask4 from "../images/industrialImages/earFaceRespiratoryProtection/mask4.jpg";
import mask5 from "../images/industrialImages/earFaceRespiratoryProtection/mask5.jpg";
import mask6 from "../images/industrialImages/earFaceRespiratoryProtection/mask6.jpg";
import res1 from "../images/industrialImages/earFaceRespiratoryProtection/res1.jpg";
import res2 from "../images/industrialImages/earFaceRespiratoryProtection/res2.jpg";
import res3 from "../images/industrialImages/earFaceRespiratoryProtection/res3.jpg";
import res4 from "../images/industrialImages/earFaceRespiratoryProtection/res4.jpg";
import res5 from "../images/industrialImages/earFaceRespiratoryProtection/res5.jpg";
import res6 from "../images/industrialImages/earFaceRespiratoryProtection/res6.png";
import res7 from "../images/industrialImages/earFaceRespiratoryProtection/res7.jpg";
import scba1 from "../images/industrialImages/earFaceRespiratoryProtection/scba1.jpg";
import scba2 from "../images/industrialImages/earFaceRespiratoryProtection/scba2.jpg";

// HAND PROTECTION
import glove1 from "../images/industrialImages/Hand_Fall_Protection/image45.jpg";
import glove2 from "../images/industrialImages/Hand_Fall_Protection/image46.jpg";
import glove3 from "../images/industrialImages/Hand_Fall_Protection/image47.jpg";
import glove4 from "../images/industrialImages/Hand_Fall_Protection/image48.jpg";
import glove5 from "../images/industrialImages/Hand_Fall_Protection/image49.png";
import glove6 from "../images/industrialImages/Hand_Fall_Protection/image50.jpg";
import glove7 from "../images/industrialImages/Hand_Fall_Protection/image51.jpg";
import glove8 from "../images/industrialImages/Hand_Fall_Protection/image52.jpg";
import glove9 from "../images/industrialImages/Hand_Fall_Protection/image33.jpg";
import glove10 from "../images/industrialImages/Hand_Fall_Protection/image34.jpg";
import glove11 from "../images/industrialImages/Hand_Fall_Protection/image42.jpg";
import glove12 from "../images/industrialImages/Hand_Fall_Protection/image35.jpg";
import glove13 from "../images/industrialImages/Hand_Fall_Protection/image36.jpg";
import glove14 from "../images/industrialImages/Hand_Fall_Protection/image37.jpg";
import glove15 from "../images/industrialImages/Hand_Fall_Protection/image38.jpg";
import glove16 from "../images/industrialImages/Hand_Fall_Protection/image31.jpg";
import glove17 from "../images/industrialImages/Hand_Fall_Protection/image39.jpg";
import glove18 from "../images/industrialImages/Hand_Fall_Protection/image40.jpg";
import glove19 from "../images/industrialImages/Hand_Fall_Protection/image41.jpg";
import glove20 from "../images/industrialImages/Hand_Fall_Protection/image32.jpg";
import glove21 from "../images/industrialImages/Hand_Fall_Protection/image67.jpg";
import glove22 from "../images/industrialImages/Hand_Fall_Protection/image68.jpg";
import glove23 from "../images/industrialImages/Hand_Fall_Protection/image69.jpg";
import glove24 from "../images/industrialImages/Hand_Fall_Protection/image70.png";
import glove25 from "../images/industrialImages/Hand_Fall_Protection/image71.jpg";
import glove26 from "../images/industrialImages/Hand_Fall_Protection/image72.jpg";
import glove27 from "../images/industrialImages/Hand_Fall_Protection/image73.jpg";
import glove28 from "../images/industrialImages/Hand_Fall_Protection/image74.png";
import glove29 from "../images/industrialImages/Hand_Fall_Protection/image65.jpg";
import glove30 from "../images/industrialImages/Hand_Fall_Protection/image57.jpg";
import glove31 from "../images/industrialImages/Hand_Fall_Protection/image58.jpg";
import glove32 from "../images/industrialImages/Hand_Fall_Protection/image59.jpg";
import glove33 from "../images/industrialImages/Hand_Fall_Protection/image60.jpg";
import glove34 from "../images/industrialImages/Hand_Fall_Protection/image64.jpg";
import glove35 from "../images/industrialImages/Hand_Fall_Protection/image66.jpg";
import glove36 from "../images/industrialImages/Hand_Fall_Protection/image53.png";
import glove37 from "../images/industrialImages/Hand_Fall_Protection/image63.jpg";

// BODY PROTECTION


// FALL PROTECTION


// FEET PROTECTION
import one from "../images/industrialImages/feetProtection/image18.png";
import two from "../images/industrialImages/feetProtection/image101.jpg";
import three from "../images/industrialImages/feetProtection/image90.jpg";
import four from "../images/industrialImages/feetProtection/image92.jpg";
import five from "../images/industrialImages/feetProtection/image94.jpg";
import six from "../images/industrialImages/feetProtection/image96.jpg";
import seven from "../images/industrialImages/feetProtection/image98.jpg";
import eight from "../images/industrialImages/feetProtection/image17.jpg";
import nine from "../images/industrialImages/feetProtection/image21.jpg";

// ELECTRICAL PROTECTION
import Eone from "../images/industrialImages/electricalProtection/elec1.png";
import Etwo from "../images/industrialImages/electricalProtection/elec2.png";
import Ethree from "../images/industrialImages/electricalProtection/elec3.png";
import Efour from "../images/industrialImages/electricalProtection/elec4.png";
import Efive from "../images/industrialImages/electricalProtection/elec5.png";
import Esix from "../images/industrialImages/electricalProtection/elec6.png";

// SHOWER PROTECTION
import S1 from "../images/industrialImages/showerProtection/shower1.png";
import S2 from "../images/industrialImages/showerProtection/shower2.png";
import S3 from "../images/industrialImages/showerProtection/shower3.png";
import S4 from "../images/industrialImages/showerProtection/shower4.png";
import S5 from "../images/industrialImages/showerProtection/shower5.png";
import S6 from "../images/industrialImages/showerProtection/shower6.png";

// SPILL-KIT PROTECTION
import SP1 from "../images/industrialImages/spillKit/spill1.png";
import SP2 from "../images/industrialImages/spillKit/spill2.png";
import SP3 from "../images/industrialImages/spillKit/spill3.png";
import SP4 from "../images/industrialImages/spillKit/spill4.png";
import SP5 from "../images/industrialImages/spillKit/spill5.png";
import SP6 from "../images/industrialImages/spillKit/spill6.png";

// Products organized by category with subcategories
const products = {
  HeadProtectionProducts: {
    subcategories: {
      Head_Protector: [
        { image: HelmetOne },
        { image: HelmetTwo },
        { image: HelmetThree },
        { image: HelmetFour },
        { image: HelmetFive },
        { image: HelmetSix },
        { image: HelmetSeven },
        { image: HelmetEight },
        { image: HelmetNine },
        { image: HelmetTen },
      ],
      BumpCap: [
        { image: Helmet11 },
        { image: Helmet12 },
        { image: Helmet13 },
        { image: Helmet14 },
      ]
    }
  },
  EyeProtectionProducts: {
    subcategories: {
      Safety_Googles: [
        { image: EyeOne },
        { image: EyeTwo },
        { image: EyeThree },
        { image: EyeFour },
        { image: EyeFive },
      ],
      Over_Specs_Goggles: [
        { image: EyeSix },
        { image: EyeSeven },
        { image: EyeEight },
      ],
      Chemical_Goggles: [
        { image: EyeNine },
        { image: EyeTen },
      ],
      Auto_IR_Welding_Goggles : [
        { image: Eye11 },
        { image: EyeT12 },
        { image: EyeT13 },
        { image: EyeT14 },
      ],
    }
  },
  EarFaceRespiratoryProtectionProducts: {
    subcategories: {
      Ear: [
        { image: EFROne },
        { image: EFRTwo },
        { image: EFR3 },
        { image: EFR5 },
        { image: EFR6 },
        { image: EFR7 },
        { image: EFR8 },
        { image: EFR9 },
      ],
      Masks: [
        { image: mask1 },
        { image: mask2 },
        { image: mask3 },
        { image: mask4 },
        { image: mask5 },
        { image: mask6 },
      ],
      Respiratory: [
        { image: res1 },
        { image: res2 },
        { image: res3 },
        { image: res4 },
        { image: res5 },
        { image: res6 },
        { image: res7 },
      ],
      SCBA_SET: [
        { image: scba1 },
        { image: scba2 },
      ],
    }
  },
  HandProtectionProducts: {
    subcategories: {
      Cotton_Knitted: [
        { image: glove1 },
        { image: glove2 },
        { image: glove3 },
      ],
      PVC_Dotted_Knitted: [
        { image: glove4 },
        { image: glove5 },
        { image: glove6 },
        { image: glove7 },
        { image: glove8 },
      ],
      Nylon_Knitted: [
        { image: glove9 },
        { image: glove10 },
        { image: glove11 },
      ],
      PU_Coated: [
        { image: glove12 },
        { image: glove13 },
      ],
      ESD_Coated: [
        {image: glove14},
        {image: glove15},
      ],
      Nitrile_Coated: [
        { image: glove16 },
        { image: glove17 },
        { image: glove18 },
        { image: glove19 },
      ],
      Latex_Coated: [
        { image: glove20 }
      ],
      Cut_Resistance: [
        { image: glove21 },
        { image: glove22 },
        { image: glove23 },
        { image: glove24 },
        { image: glove25 },
        { image: glove26 },
        { image: glove27 },
        { image: glove28 },
      ],
      Chemical_Resistance: [
        { image: glove29 },
        { image: glove30 },
        { image: glove31 },
        { image: glove32 },
        { image: glove33 },
        { image: glove34 },
        { image: glove35 },
      ],
      Heat_Resistance: [
        { image: glove36 },
        { image: glove37 },
      ],
    }
  },
  // BodyProtectionProducts: {
  //   subcategories: {
  //     SafetyJackets: [
  //       { name: "Raincoat", image: BodyOne },
  //       { name: "Yellow Reflective Jacket", image: BodyTwo },
  //     ],
  //     ProtectiveClothing: [
  //       { name: "Welding Leather Gloves", image: BodyThree },
  //       { name: "Welding Jacket", image: BodyFour },
  //     ],
  //   }
  // },
  // FallProtectionProducts: {
  //   subcategories: {
  //     Harness: [
  //       { name: "Full Body Harness", image: FallOne },
  //       { name: "Fall Arrest Harness", image: FallTwo },
  //     ],
  //     Lanyards: [
  //       { name: "Shock Absorbing Lanyard", image: FallThree },
  //       { name: "Twin-Leg Lanyard", image: FallFour },
  //     ],
  //   }
  // },
  FeetProtectionProducts: {
    subcategories: {
      Safety_Shoes: [
        { name: "Steel Toe Safety Boots", image: one },
        { name: "Rubber Safety Boots", image: two },
        { name: "Safety Shoes", image: three },
        { name: "Slip-Resistant Shoes", image: four },
        { name: "Slip-Resistant Shoes", image: five },
        { name: "Slip-Resistant Shoes", image: six },
        { name: "Slip-Resistant Shoes", image: seven },
      ],
      Safety_Toe_Guard: [
        { name: "Safety Shoes", image: eight },
        { name: "Slip-Resistant Shoes", image: nine },
      ],
    }
  },
  ElectricalProtectionProducts: {
    subcategories: {
      Gloves: [
        { name: "Rubber Insulated Gloves", image: Eone },
        { name: "Leather Protector Gloves", image: Etwo },
      ],
      Tools: [
        { name: "Insulated Screwdrivers", image: Ethree },
        { name: "Insulated Pliers", image: Efour },
      ],
    }
  },
  ShowerProtectionProducts: {
    subcategories: {
      SafetyShowers: [
        { name: "Emergency Safety Shower", image: S1 },
        { name: "Eyewash Shower", image: S2 },
      ],
      SpillKits: [
        { name: "Basic Spill Kit", image: SP1 },
        { name: "Deluxe Spill Kit", image: SP2 },
      ],
    }
  },
};

// Popup component should be defined before using hooks
const Popup = ({ isOpen, onClose, category }) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const categoryData = products[category];

  useEffect(() => {
    if (categoryData) {
      const subcategories = Object.keys(categoryData.subcategories);
      setSelectedSubcategory(subcategories[0]); // Initialize the first subcategory
    }
  }, [categoryData]);

  if (!isOpen || !categoryData) return null;

  const subcategories = Object.keys(categoryData.subcategories);
  const categoryProducts = categoryData.subcategories[selectedSubcategory] || [];

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
        <p
          style={{
            color: "#50C878",
            fontSize: "30px",
            fontWeight: "bold",
            letterSpacing: "2px",
            background: "purple",
            borderRadius: "5px",
          }}
        >
          {category.replace(/([A-Z])/g, " $1").trim()}
        </p>
        <div className="subcategory-nav">
          {subcategories.map((subcategory, index) => (
            <button
              key={index}
              className="subcategory-btn"
              onClick={() => handleSubcategoryChange(subcategory)}
              style={{
                background: selectedSubcategory === subcategory ? "#50C878" : "black",
                color: selectedSubcategory === subcategory ? "black" : "white",
              }}
            >
              {subcategory}
            </button>
          ))}
        </div>
        <div className="products">
          {categoryProducts.map((product, index) => (
            <div key={index} className="product-card">
              <img
                src={product.image}
                alt={product.name}
                className="product-image"
              />
              <div className="product-details">
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Industrial = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handlePopupOpen = (category) => {
    setSelectedCategory(category);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setSelectedCategory("");
  };

  return (
    <div className="industrial-container">
      <h2 className="heading">Industrial Safety Products</h2>
      <div className="products">
        {Object.keys(products).map((category, index) => (
          <div key={index} className="product-card">
            <img
              src={products[category].subcategories ? products[category].subcategories[Object.keys(products[category].subcategories)[0]][0].image : ""}
              alt={category}
              className="product-image"
              onClick={() => handlePopupOpen(category)}
            />
            <p className="product-name">
              {category.replace(/([A-Z])/g, " $1").trim()}
            </p>
          </div>
        ))}
      </div>
      <Popup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        category={selectedCategory}
      />
    </div>
  );
};

export default Industrial;
