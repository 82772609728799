import React from "react";
import "../css/AboutUs.css";
import { AboutImage } from "./HomeProductSegment";

const About = () => {
  return (
    <>
      <div className="aboutContainer">
        <p className="heading">
          <span style={{ color: "red", textDecoration: "underline" }}>
            {" "}
            About Us
          </span>
        </p>
        <div className="overview_image">
          <div className="overview">
            <p className="overHead" style={{ textDecoration: "underline" }}>
              Overview
            </p>
            <p className="overPara">
              At RKH Industries, saftey is not just a priority-it's a mission.
              As a leading provider of safety solutions, we are dedicated to
              protecting workers, workplaces, and industries across the globe.
              With a committment to innovation, quality, and expertise, we
              deliver comprehensive safety products, services, and training that
              minimize risk and maximize protection.
            </p>
          </div>
          <div className="image">
            <AboutImage />
          </div>
        </div>
        <div className="vision_mission">
          <div className="vision">
            <h2 className="sectionTitle visionTitle">Our Vision</h2>
            <p className="sectionText">
              RKH Industries has been a trusted authority in the safety
              industry. We work with businesses across construction, oil and
              gas, manufacturing, healthcare, and other high-risk sectors to
              create safer working environments. Our goal is simple: to ensure
              that every worker returns home safely.
            </p>
             
          </div>
          <div className="mission">
            <h2 className="sectionTitle missionTitle">Our Mission</h2>
            <p className="sectionText">
              To lead the way in creating safer workplaces through cutting-edge
              solutions, expert knowledge, and an unwavering committment to
              excellence. We believe safety is non-negotiable, and we are driven
              by the vision of a world where workplace injuries and aceeidents
              are a thing of the past.
            </p>
          </div>
        </div>
      </div>
      <div className="history_values">
        <div className="history">
          <h2 className="sectionTitle">Company History</h2>
          <p className="sectionText">
            Founded in January 2024, RKH Industries was created with a clear
            mission: to enhance workplace safety and help businesses navigate
            the complex landscape of safety regulations. Our team brings years
            of combined experience in occupational safety, risk management, and
            compliance. We are driven by a passion for creating safer, healthier
            work environments for organizations across all industries. From
            safety audits and risk assessments to tailored employee training, we
            offer comprehensive safety solutions designed to meet the unique
            needs of each client. At RKH Industries, we believe that safety
            isn’t just about compliance—it’s about protecting lives, fostering a
            strong safety culture, and improving operational efficiency from day
            one."
          </p>
        </div>
        <div className="coreValues">
          <h2 className="sectionTitle">Core Values</h2>
          <ul className="valuesList">
            <li>
              <strong>Integrity:</strong> We uphold the highest ethical
              standards in all our operations.
            </li>
            <li>
              <strong>Innovation:</strong> We continuously seek innovative
              solutions to improve safety and efficiency.
            </li>
            <li>
              <strong>Customer Focus:</strong> We are committed to understanding
              and meeting the needs of our customers.
            </li>
            <li>
              <strong>Excellence:</strong> We strive for excellence in
              everything we do, from product development to customer service.
            </li>
            <li>
              <strong>Sustainability:</strong> We are dedicated to environmental
              responsibility and sustainable practices.
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="team_awards">
        <div className="team">
          <h2 className="sectionTitle">Meet Our Team</h2>
          <p className="sectionText">
            Our team is our greatest asset. Composed of industry experts,
            engineers, and dedicated professionals, each member plays a critical
            role in maintaining our standards of excellence. We believe in
            fostering a collaborative and innovative work environment where
            everyone contributes to our mission of providing top-quality safety
            solutions.
          </p>
          <div className="teamImages">
            <img
              src="team-member1.jpg"
              alt="Team Member 1"
              className="teamImage"
            />
            <img
              src="team-member2.jpg"
              alt="Team Member 2"
              className="teamImage"
            />
          </div>
        </div>
        <div className="awards">
          <h2 className="sectionTitle">Awards & Recognition</h2>
          <p className="sectionText">
            Over the years, RKH Industries has been honored with numerous awards
            recognizing our commitment to quality and safety. These accolades
            are a testament to our dedication to providing the best safety
            solutions and our impact on the industry.
          </p>
        </div>
      </div> */}
      <div className="space">
        <div className="cta">
          <h2 className="sectionTitle">Get In Touch</h2>
          <p className="sectionText">
            Want to learn more about our products and services? Feel free to{" "}
            <a href="/contact" className="ctaLink">
              contact us
            </a>{" "}
            for more information or schedule a consultation with our experts.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
