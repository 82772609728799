import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import "../css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section">
            <h3>About Us</h3>
            <p>
              Leading the way in mechanical innovations and solutions. Discover
              our services and projects.
            </p>
          </div>
          <div className="footer-section">
            <h3>Contact Us</h3>
            <address>
              <p>
                Plot No: 8A RIICO Industrial Area,
                <br />
                BHIWADI, NEAR ALWAR BYPASS Rd.
                <br />
                Rajasthan 301019
              </p>
              <p>
                Email: srkhindustries@gmail.com
                <br />
                Phone: +91 8740977797
              </p>
            </address>
          </div>
          <div className="footer-section">
            <h3>Follow Us</h3>
            <div className="social-links">
              <a
                href="https://www.facebook.com/profile.php?id=61567820159981"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/rkh_industries/profilecard/?igsh=MXN2bmJxbzdyNnh5dw=="
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.youtube.com/@RKHIndustries01"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} Mechanical Industry. All rights
            reserved.
          </p>
          <p style={{ marginTop: "10px" }}>
            Developed By - <b>CodeCrush Hub</b>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
