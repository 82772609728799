// src/components/Road.js
import React from 'react';
import '../css/Industrial.css';

import SB from '../images/roadImages/Road1.png';
import Road1 from '../images/roadImages/Road2.png';
import Road2 from "../images/roadImages/Road3.png";
import Road3 from "../images/roadImages/Road4.png";
import Road4 from "../images/roadImages/Road5.png";
import Road5 from "../images/roadImages/Road6.png";
import Road6 from "../images/roadImages/Road7.png";
import Road7 from "../images/roadImages/Road8.png";
import Road8 from "../images/roadImages/Road9.png";
import Road9 from "../images/roadImages/Road10.png";
import Road10 from "../images/roadImages/Road11.png";
import Road11 from "../images/roadImages/Road12.png";
import Road12 from "../images/roadImages/Road13.png";
import Road13 from "../images/roadImages/Road14.png";
import Road14 from "../images/roadImages/Road15.png";
import Road15 from "../images/roadImages/Road16.png";
import Road16 from "../images/roadImages/Road17.png";
import Road17 from "../images/roadImages/Road18.png";
import Road18 from "../images/roadImages/Road19.png";
import Road19 from "../images/roadImages/Road20.png";
import Road20 from "../images/roadImages/Road21.png";
import Road21 from "../images/roadImages/Road22.png";

// Define main product list
const products = [
  { name: "Rumbler Strips Pioneer", image: SB },
  { name: "Line Manager With Belt", image: Road1 },
  { name: "250x350x50mm Pioneer", image: Road2 },
  { name: "Automatic Boom Barrier", image: Road3 },
  { name: "Man With Arrow", image: Road4 },
  { name: "250x350x50mm Protector", image: Road5 },
  { name: "250x500x80mm Sloway", image: Road6 },
  { name: "Caution Tape Electric Cable", image: Road7 },
  { name: "3M Multicolor Cat Eyes", image: Road8 },
  { name: "Sleeve Heavy Rubber Base Orange Pioneer & Roto Mould Red Safedot", image: Road9 },
  { name: "3M Retro Reflective Tapes", image: Road10 },
  { name: "Dome Mirror Hall Protector", image: Road11 },
  { name: "Solar Road Stud Cat Eye", image: Road12 },
  { name: "Convex Mirror 1200mm", image: Road13 },
  { name: "Dome Mirror Full Protector", image: Road14 },
  { name: "Wheel Stopper Truck Choke with Rope Protector", image: Road15 },
  { name: "Caution Tape Fire Cable", image: Road16 },
  { name: "Expandable Barrier L/ D", image: Road17 },
  { name: "Caution Floor Stand Protector", image: Road18 },
  { name: "Water Fillable Barrier", image: Road19 },
  { name: "Night Glow in Center Tape", image: Road20 },
  { name: "Caution Tape ELV Cable Line", image: Road21 },
];

const Road = () => {
  return (
    <div className="industrial-container">
      <h2 className="heading">Road Safety Products</h2>
      <div className="products">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <p className="product-name">{product.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Road;
