import React from 'react';
import Honda from "../images/majorClients/client1Honda.webp";
import SRF from "../images/majorClients/clientSRF.png";
import SC from "../images/majorClients/clientSiegwerk.jpg";
import K from "../images/majorClients/clientKumi.jpeg";
import CP from "../images/majorClients/ClientCP.png";
import NSSI from "../images/majorClients/clientNSSI.png";
import Lotte from "../images/majorClients/clientLotte.png";
import Carls from "../images/majorClients/clientCarls.jpg"; 

export const HondaClient = () => {
    return (
        <img src={ Honda } alt="Honda Client"className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const SRFClient = () => {
    return (
        <img src={ SRF } alt="SRF Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const SeigwerkClient = () => {
    return (
        <img src={ SC } alt="Seigwerk Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const KClient = () => {
    return (
        <img src={ K } alt="Kumi Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const CPClient = () => {
    return (
        <img src={ CP } alt="CP Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const NSSIClient = () => {
    return (
        <img src={ NSSI } alt="CP Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const LotteClient = () => {
    return (
        <img src={ Lotte } alt="CP Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}

export const CarlsClient = () => {
    return (
        <img src={ Carls } alt="CP Client" className='clientImg' style={{mixBlendMode:"multiply"}}/>
    )
}
