import React from "react";
import video1 from "../videos/v1.mp4";
import video2 from "../videos/v2.mp4";
import video3 from "../videos/v3.mp4";
import video4 from "../videos/v4.mp4";
import video5 from "../videos/v5.mp4";
import video6 from "../videos/v6.mp4";
import '../css/media.css';

const Media = () => {
  return (
    <div>
      <h1 className="mediaHeading">Media Page</h1>
      <div className="video-container">
        <div className="video-item">
          <h2>Video 1</h2>
          <video width="320" height="240" controls>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-item">
          <h2>Video 2</h2>
          <video width="320" height="240" controls>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-item">
          <h2>Video 3</h2>
          <video width="320" height="240" controls>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-item">
          <h2>Video 4</h2>
          <video width="320" height="240" controls>
            <source src={video4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-item">
          <h2>Video 5</h2>
          <video width="320" height="240" controls>
            <source src={video5} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-item">
          <h2>Video 6</h2>
          <video width="320" height="240" controls>
            <source src={video6} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Media;
