import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../css/Header.css";
import { CompanyLogo } from "./HomeProductSegment";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const menuRef = useRef(null);
  const productsRef = useRef(null);

  // Toggle the main menu
  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
    if (isProductsOpen) {
      setIsProductsOpen(false);
    }
  };

  // Toggle the Products dropdown
  const toggleProductsDropdown = (event) => {
    event.stopPropagation(); // Prevent click event from closing the menu
    setIsProductsOpen(prevState => !prevState);
  };

  // Close the menu and dropdown when a link is clicked
  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsProductsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (productsRef.current && !productsRef.current.contains(event.target)) {
        setIsProductsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <div className="headerDiv">
        <div className="CompanyLogo">
          <CompanyLogo />
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          ☰
        </div>
        <div ref={menuRef} className={`links ${isMenuOpen ? 'show' : ''}`}>
          <Link to="/home" onClick={handleLinkClick}>Home</Link>
          <div className="dropdown" ref={productsRef}>
            <button className="dropdown-button" onClick={toggleProductsDropdown} style={{fontWeight: 'bold',   fontSize: '20px'}}>
              Products
            </button>
            {isProductsOpen && (
              <div className="dropdown-menu">
                <Link to="/products/industrial" className="dropdown-item" onClick={handleLinkClick}>
                  Industrial Product Safety
                </Link>
                <Link to="/products/fire" className="dropdown-item" onClick={handleLinkClick}>
                  Fire Product Safety
                </Link>
                <Link to="/products/road" className="dropdown-item" onClick={handleLinkClick}>
                  Road Product Safety
                </Link>
                <Link to="/products/loto" className="dropdown-item" onClick={handleLinkClick}>
                  LOTO Product  Safety
                </Link>
                <Link to="/products/Special" className="dropdown-item" onClick={handleLinkClick}>
                  Special Product Safety
                </Link>
              </div>
            )}
          </div>
          <Link to="/about" onClick={handleLinkClick}>About Us</Link>
          <Link to="/contact" onClick={handleLinkClick}>Contact</Link>
          <Link to="/media" onClick={handleLinkClick}>Media </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
