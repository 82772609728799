import React from "react";
import "../css/Industrial.css";

// Import images directly
import loto1 from "../images/lotoImages/loto1.png";
import loto2 from "../images/lotoImages/loto2.png";
import loto3 from "../images/lotoImages/loto3.png";
import loto4 from "../images/lotoImages/loto4.png";
import loto5 from "../images/lotoImages/loto5.png";
import loto6 from "../images/lotoImages/loto6.png";
import loto7 from "../images/lotoImages/loto7.png";
import loto8 from "../images/lotoImages/loto8.png";
import loto9 from "../images/lotoImages/loto9.png";
import loto10 from "../images/lotoImages/loto10.png";
import loto11 from "../images/lotoImages/loto11.png";
import loto12 from "../images/lotoImages/loto12.png";
import loto13 from "../images/lotoImages/loto13.png";
import loto14 from "../images/lotoImages/loto14.png";
import loto15 from "../images/lotoImages/loto15.png";
import loto16 from "../images/lotoImages/loto16.png";
import loto17 from "../images/lotoImages/loto17.png";
import loto18 from "../images/lotoImages/loto18.png";
import loto19 from "../images/lotoImages/loto19.png";
import loto20 from "../images/lotoImages/loto20.png";
import loto21 from "../images/lotoImages/loto21.png";
import loto22 from "../images/lotoImages/loto22.png";
import loto23 from "../images/lotoImages/loto23.png";
import loto24 from "../images/lotoImages/loto24.png";
import loto25 from "../images/lotoImages/loto25.png";
import loto26 from "../images/lotoImages/loto26.png";
import loto27 from "../images/lotoImages/loto27.png";
import loto28 from "../images/lotoImages/loto28.png";
import loto29 from "../images/lotoImages/loto29.png";
import loto30 from "../images/lotoImages/loto30.png";
import loto31 from "../images/lotoImages/loto31.png";
import loto32 from "../images/lotoImages/loto32.png";
import loto33 from "../images/lotoImages/loto33.png";
import loto34 from "../images/lotoImages/loto34.png";
import loto35 from "../images/lotoImages/loto35.png";
import loto36 from "../images/lotoImages/loto36.png";
import loto37 from "../images/lotoImages/loto37.png";
import loto38 from "../images/lotoImages/loto38Final.png";

// Products organized by category
const products = {
  FireSafety: [
    {
      name: "Double Jaw",
      image: loto1,
    },
    {
      name: "Di Electric Shackle",
      image: loto2,
    },
    {
      name: "Vinyl Coated Jaw dia",
      image: loto3,
    },
    {
      name: "Di Electric double jaw",
      image: loto4,
    },
    {
      name: "Tago Label M.S.",
      image: loto5,
    },
    {
      name: "Metallic Multifunction",
      image: loto6,
    },
    {
      name: "Non Conductive 6 Hole",
      image: loto7,
    },
    {
      name: "Non Conductive 4 Hole",
      image: loto8,
    },
    {
      name: "MCB Curvature Shape Small",
      image: loto9,
    },
    {
      name: "Butterfly Valve",
      image: loto10,
    },
    {
      name: "Gate Valve",
      image: loto11,
    },
    {
      name: "Double Leg open back",
      image: loto12,
    },
    {
      name: "Scaffolding Tag",
      image: loto13,
    },
    {
      name: "Circuit Breaker 1",
      image: loto14,
    },
    {
      name: "MCB Lockout Pin - Circuit Breaker",
      image: loto15,
    },
    {
      name: "Circuit Breaker 2",
      image: loto16,
    },
    {
      name: "38mm Nylon Shackle",
      image: loto17,
    },
    {
      name: "MCB Fordable Screw",
      image: loto18,
    },
    {
      name: "Adjustable Gate Valve Device",
      image: loto19,
    },
    {
      name: "Br38 Circuit Breaker",
      image: loto20,
    },
    {
      name: "76mm Nylon Shackle",
      image: loto21,
    },
    {
      name: "Round Di-electric Lockout",
      image: loto22,
    },
    {
      name: "Square Small, 2 locking hole",
      image: loto23,
    },
    {
      name: "Metallic Multipurpose Cable Lockout",
      image: loto24,
    },
    {
      name: "Lockout Station",
      image: loto25,
    },
    {
      name: "Do Not Operate",
      image: loto26,
    },
    {
      name: "Electrical Panel Handle Lockout",
      image: loto27,
    },
    {
      name: "Padlock & Key Lockout Station",
      image: loto28,
    },
    {
      name: "LOTO Plant & Machinery Kit",
      image: loto29,
    },
    {
      name: "Mushroom Emergency Push Button",
      image: loto30,
    },
    {
      name: "Small Plug Lockout, 4 locking holes'",
      image: loto31,
    },
    {
      name: "Double Door Key & Padlock Lockout Cabinet'",
      image: loto32,
    },
    {
      name: "OSHA Electrical Safety Loto Kit With Station'",
      image: loto33,
    },
    {
      name: "3 IN 1 Electrical Lockout'",
      image: loto34,
    },
    {
      name: "Padlock Station",
      image: loto35,
    },
    {
      name: "Dojo Learning Board",
      image: loto36,
    },
    {
      name: "Open Lockout Padlock Hasp & Tags Station",
      image: loto37,
    },
    {
      name: "OSHA Safety LOTO Padlock with 24inch wire",
      image: loto38,
    },
  ],
};

// Main Fire component
const Fire = () => {
  return (
    <div className="industrial-container">
      <h2 className="heading">Fire Safety Products</h2>
      <div className="products">
        {products.FireSafety.map((product, index) => (
          <div key={index} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <p className="product-name">{product.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fire;
