import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeCarousel from "./HomeCarousel";
import "../css/Home.css";
import { SegOne, Fire, Road, Loto } from "./HomeProductSegment";
// import Testimonials from "./Testimonials";
import { useNavigate } from "react-router-dom";
import {
  HondaClient,
  SRFClient,
  SeigwerkClient,
  KClient,
  CPClient,
  NSSIClient,
  LotteClient,
  CarlsClient,
} from "./MajorClients";

const Home = () => {
  const navigate = useNavigate();

  const openIndustrialProducts = () => {
    navigate("/products/industrial");
  };

  const openFireProducts = () => {
    navigate("/products/fire");
  };

  const openRoadProducts = () => {
    navigate("/products/road");
  };

  const openLotoProducts = () => {
    navigate("/products/loto");
  };

  const openSpecialProducts = () => {
    navigate("/products/special");
  };

  const downloadFile = (event) => {
    event.preventDefault(); // Prevent default link behavior

    const fileUrl = "/brochure/RKHIndustries.pdf"; // Adjusted path

    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          console.error("Failed to load:", response.statusText);
          throw new Error("File not available");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "RKHIndustries.pdf"; // Filename for download
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Clean up
      })
      .catch((error) => {
        alert(
          "Error: Unable to download the catalogue. Please try again later."
        );
        console.error("Download error:", error);
      });
  };

  return (
    <>
      <div>
        <HomeCarousel />
      </div>
      <div className="segments">
        <p className="segmentText">our segments</p>
        <div className="productCategory">
          {/* first category */}
          <div className="category">
            <p className="text">Industrial Safety Products</p>
            <div>
              <SegOne />
            </div>
            <button className="ViewProduct" onClick={openFireProducts}>
              View Products
            </button>
          </div>

          {/* second category */}
          <div className="category">
            <p className="text">Fire Safety Products</p>
            <div>
              <Fire />
            </div>
            <button className="ViewProduct" onClick={openFireProducts}>
              View Products
            </button>
          </div>

          {/* third category */}
          <div className="category">
            <p className="text">Road Safety Products</p>
            <div>
              <Road />
            </div>
            <button className="ViewProduct" onClick={openRoadProducts}>
              View Products
            </button>
          </div>

          {/* fourth category */}
          <div className="category">
            <p className="text">LOTO Safety Products</p>
            <div>
              <Loto />
            </div>
            <button className="ViewProduct" onClick={openLotoProducts}>
              View Products
            </button>
          </div>

          {/* fifth category */}
          <div className="category">
            <p className="text">Special Safety Products</p>
            <div>
              <SegOne />
            </div>
            <button className="ViewProduct" onClick={openSpecialProducts}>
              View Products
            </button>
          </div>
        </div>
      </div>

      {/* <div>
        <Testimonials /> 
      </div> */}
      {/* Catalogue Section */}
      <div className="catalogue-section">
        <div className="catalogue-content">
          <h2>
            Download Our <span className="cata">Catalogue</span>{" "}
          </h2>
          <p>
            Explore our range of <span className="red">products</span> and{" "}
            <span className="blue">services</span> in our detailed catalogue.
            Click the button below to download the PDF.
          </p>
          <a
            href="#"
            className="catalogue-button"
            onClick={downloadFile} // Use the React onClick handler
          >
            Download Catalogue
          </a>
        </div>
      </div>

      {/* OUR MAJOR CLIENTS SECTION */}
      <div className="clients-section">
        <h2 style={{ color: "green" }}>Our Major Clients</h2>
        <div className="clients-container">
          <div className="client-item">
            <HondaClient />
          </div>
          <div className="client-item">
            <SRFClient />
          </div>
          <div className="client-item">
            <SeigwerkClient />
          </div>
          <div className="client-item">
            <KClient />
          </div>
          <div className="client-item">
            <CPClient />
          </div>
          <div className="client-item">
            <NSSIClient />
          </div>
          <div className="client-item">
            <LotteClient />
          </div>
          <div className="client-item">
            <CarlsClient />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
