import React, { useState } from "react";
import "../css/Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    requirements: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Example submission logic (replace with your own)
    const formSubmissionData = new FormData();
    formSubmissionData.append("access_key", "eea4b7d3-04b7-476b-aacb-becc30e5337f");
    formSubmissionData.append("fullName", formData.fullName);
    formSubmissionData.append("email", formData.email);
    formSubmissionData.append("mobileNumber", formData.mobileNumber);
    formSubmissionData.append("requirements", formData.requirements);

    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formSubmissionData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(`Form submitted successfully! Thank you, ${formData.fullName}!`);
          setFormData({ fullName: "", email: "", mobileNumber: "", requirements: "" }); // Reset form data
        } else {
          alert("Error: " + data.message);
        }
      })
      .catch((error) => {
        alert("There was an error submitting the form: " + error.message);
      });
  };

  return (
    <>
      <div className="contact-container">
        <div className="contact-info">
          <h2>Reach out to us</h2>
          <p>
            Have questions or need a quote for our services? Reach out today.
            We're here to help!
          </p>
          <ul>
            <li>
              <strong>Visit us:</strong> 8A RIICO Industrial Area, BHIWADI, NEAR
              ALWAR BYPASS Rd. Rajasthan
            </li>
            <li>
              <strong>Email:</strong> srkhindustries@gmail.com
            </li>
            <li>
              <strong>Call:</strong> +91 8740977797{" "}
            </li>
            <li>
              <strong>Office Timings:</strong> 09:30 a.m. to 06:00 p.m.{" "}
            </li>
          </ul>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="access_key"
            value="eea4b7d3-04b7-476b-aacb-becc30e5337f"
          />
          <input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            required
            autoComplete="off"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            autoComplete="off"
          />
          <input
            type="tel"
            name="mobileNumber"
            placeholder="Mobile Number"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
            autoComplete="off"
          />
          <textarea
            name="requirements"
            placeholder="Detail Your Requirements"
            value={formData.requirements}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="ViewProduct">
            Submit
          </button>
        </form>
      </div>

      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3516.0119778050907!2d76.85513223985218!3d28.206948676000202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3790ffd73079%3A0xe012053497ef4a67!2s8%2C%20RIICO%20Industrial%20Area%2C%20Bhiwadi%2C%20Rajasthan%20301019!5e0!3m2!1sen!2sin!4v1725786188751!5m2!1sen!2sin"
          width="90%"
          height="500"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
